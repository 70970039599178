import Vue from "vue";
import Vuex from "vuex";

import { callInfo } from './modules/callInfo'
import { dialogCont } from './modules/dialogCont'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authorities: [],
    userInfo: {
      photo: '',
      name: "",
      username: '',
      mobile: '',
      id: '',
      roleIdList: [],
      orgId: "",
      orgName: "",
    },
    fixNavmenuActiveKey: null,
    refreshClockKey: null,
    navIsOpen: true,
    pageHistoryInfo: {
      "callHistoryPreview": null,   // 拨打统计 跳转 拨打记录
      "intentionPreview": null,     // 意向统计 跳转 意向列表
      "dataImportList": null,       // 数据列表 跳转 单个数据批次 
      "otherSourceList": null,      // 其他来源列表 跳转 重复详情和批次详情
      "useDataAuditList": null,  // 外呼数据审核列表 跳转审核详情
    },
  },
  mutations: {
    updateAuthorities(state, authorities){
      state.authorities = authorities
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = { ...state.userInfo, ...userInfo}
    },
    updateFixNavmenuActiveKey(state, fixNavmenuActiveKey) {
      state.fixNavmenuActiveKey = fixNavmenuActiveKey
    },
    updateNavIsOpen(state, navIsOpen){
      state.navIsOpen = navIsOpen
    },
    updatePageHistoryInfo(state, pageHistoryInfo) {
      state.pageHistoryInfo = { ...state.pageHistoryInfo, ...pageHistoryInfo }
    },
    updateRefreshClockKey(state, refreshClockKey) {
      state.refreshClockKey = refreshClockKey
    },
  },
  actions: {},
  modules: {
    callInfo,
    dialogCont,
  },
});
