import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/views/login.vue";
import notFound from '@/views/404.vue'
/* import home from '@/views/home.vue'
import homeType2 from '@/views/homeType2.vue' */
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => { })
}

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    redirect: '/login',
    meta: {
      title: '登录',
      breadList: ['登录'],
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: '登录',
      breadList: ['登录'],
    },
  },
  {
    path: "/seeLive",
    name: "seeLive",
    component: () =>
      import(/* webpackChunkName: "seeLive" */ "../views/seeLive.vue"),
    meta: {
      title: '课堂旁听',
      breadList: ['课堂旁听'],
    },
  },
  {
    path: "/seeLivev2",
    name: "seeLivev2",
    component: () =>
      import(/* webpackChunkName: "seeLivev2" */ "../views/seeLivev2.vue"),
    meta: {
      title: '课堂旁听',
      breadList: ['课堂旁听'],
    },
  },
  {
    path: "/seeLiveOneToMore",
    name: "seeLiveOneToMore",
    component: () =>
      import(/* webpackChunkName: "seeLiveOneToMore" */ "../views/seeLiveOneToMore.vue"),
    meta: {
      title: '课堂旁听',
      breadList: ['课堂旁听'],
    },
  },
  {
    path: "/otherTest",
    name: "otherTest",
    component: () =>
      import(/* webpackChunkName: "otherTest" */ "../views/otherTest.vue"),
    meta: {
      title: '测试页面',
      breadList: ['测试页面'],
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => {
      const useCallPlatform = sessionStorage.getItem("useCallPlatform")
      const isCanSwitchLine = sessionStorage.getItem("isCanSwitchLine")
      if (isCanSwitchLine) {
        console.log("useCallPlatform all")
          return import(/* webpackChunkName: "homeTypeAll" */ "../views/homeTypeAll.vue")
      } else {
        // useCallPlatform 1 点控云 2 优音云 3 数企  4 连连 5 千创云
        if (useCallPlatform == 2) {
          console.log("useCallPlatform 2")
          return import(/* webpackChunkName: "homeType2" */ "../views/homeType2.vue")
        } else if (useCallPlatform == 3) {
          console.log("useCallPlatform 3")
          return import(/* webpackChunkName: "homeType3" */ "../views/homeType3.vue")
        } else if (useCallPlatform == 4) {
          console.log("useCallPlatform 4")
          return import(/* webpackChunkName: "homeType4" */ "../views/homeType4.vue")
        } else if (useCallPlatform == 5) {
          console.log("useCallPlatform 5")
          return import(/* webpackChunkName: "homeType5" */ "../views/homeType5.vue")
        } else if (useCallPlatform == 1) {
          console.log("useCallPlatform 1")
          return import(/* webpackChunkName: "home" */ "../views/home.vue")
        } else {
          console.log("useCallPlatform 0")
          return import(/* webpackChunkName: "homeType3" */ "../views/homeType3.vue")
        }
      }
    },
    meta: {
      title: '首页',
      breadList: ['首页'],
    },
    children: [
      {
        path: "/welcome",
        name: "welcome",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "welcome" */ "../views/welcome.vue"),
        meta: {
          title: '首页',
          breadList: ['首页'],
        },
      },
      /* 工作台 */
      {
        path: "/workBench/incomeDetail",
        name: "workBench_incomeDetail",
        component: () =>
          import(/* webpackChunkName: "workBench_incomeDetail" */ "../views/workBench/incomeDetail.vue"),
        meta: {
          title: '收入明细',
          breadList: ['工作台', '收入明细'],
        },
      },
      {
        path: "/workBench/salePreview",
        name: "workBench_salePreview",
        component: () =>
          import(/* webpackChunkName: "workBench_salePreview" */ "../views/workBench/salePreview.vue"),
        meta: {
          title: '销售概览',
          breadList: ['工作台', '销售概览'],
        },
      },
      {
        path: "/workBench/orderPreview",
        name: "workBench_orderPreview",
        component: () =>
          import(/* webpackChunkName: "workBench_orderPreview" */ "../views/workBench/orderPreview.vue"),
        meta: {
          title: '签单行为概览',
          breadList: ['工作台', '签单行为概览'],
        },
      },
      {
        path: "/workBench/teacherPreview",
        name: "workBench_teacherPreview",
        component: () =>
          import(/* webpackChunkName: "workBench_teacherPreview" */ "../views/workBench/teacherPreview.vue"),
        meta: {
          title: '教师概览',
          breadList: ['工作台', '教师概览'],
        },
      },
      {
        path: "/workBench/tryPreview",
        name: "workBench_tryPreview",
        component: () =>
          import(/* webpackChunkName: "workBench_tryPreview" */ "../views/workBench/tryPreview.vue"),
        meta: {
          title: '试听概览',
          breadList: ['工作台', '试听概览'],
        },
      },
      /* 系统 */
      {
        path: "/sysManage/menuManage",
        name: "menuManage",
        component: () =>
          import(/* webpackChunkName: "menuManage" */ "../views/sysManage/menuManage.vue"),
        meta: {
          title: '菜单管理',
          breadList: ['系统管理', '菜单管理'],
        },
      },
      {
        path: "/sysManage/roleManage",
        name: "roleManage",
        component: () =>
          import(/* webpackChunkName: "roleManage" */ "../views/sysManage/roleManage.vue"),
        meta: {
          title: '角色管理',
          breadList: ['系统管理', '角色管理'],
        },
      },
      {
        path: "/sysManage/userManage",
        name: "userManage",
        component: () =>
          import(/* webpackChunkName: "userManage" */ "../views/sysManage/userManage.vue"),
        meta: {
          title: '用户管理',
          breadList: ['系统管理', '用户管理'],
        },
      },
      {
        path: "/sysManage/areaManage",
        name: "areaManage",
        component: () =>
          import(/* webpackChunkName: "areaManage" */ "../views/sysManage/areaManage.vue"),
        meta: {
          title: '行政区域',
          breadList: ['系统管理', '区域管理'],
        },
      },
      {
        path: "/sysManage/publicSourceManage",
        name: "publicSourceManage",
        component: () =>
          import(/* webpackChunkName: "publicSourceManage" */ "../views/sysManage/publicSourceManage.vue"),
        meta: {
          title: '公共资源库',
          breadList: ['系统管理', '公共资源库'],
        },
      },
      {
        path: "/sysManage/macroManage",
        name: "macroManage",
        component: () =>
          import(/* webpackChunkName: "macroManage" */ "../views/sysManage/macroManage.vue"),
        meta: {
          title: '通用字典',
          breadList: ['系统管理', '通用字典'],
        },
      },
      {
        path: "/sysManage/organManage",
        name: "organManage",
        component: () =>
          import(/* webpackChunkName: "organManage" */ "../views/sysManage/organManage.vue"),
        meta: {
          title: '机构管理',
          breadList: ['系统管理', '机构管理'],
        },
      },
      /* 讲师 */
      {
        path: "/teacher/teacherManage/teacherList",
        name: "teacherManage_teacherList",
        component: () =>
          import(/* webpackChunkName: "teacherManage_teacherList" */ "../views/teacher/teacherManage/teacherList.vue"),
        meta: {
          title: '讲师列表',
          breadList: ['讲师', '讲师管理', '讲师列表'],
        },
      },
      {
        path: "/teacher/teacherAudit/teacherList",
        name: "teacherAudit_teacherList",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_teacherList" */ "../views/teacher/teacherAudit/teacherList.vue"),
        meta: {
          title: '讲师列表',
          breadList: ['讲师', '讲师审核', '讲师列表'],
        },
      },
      {
        path: "/teacher/teacherAudit/adviserList",
        name: "teacherAudit_adviserList",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_adviserList" */ "../views/teacher/teacherAudit/adviserList.vue"),
        meta: {
          title: '讲师顾问',
          breadList: ['讲师', '讲师审核', '讲师顾问'],
        },
      },
      {
        path: "/teacher/teacherAudit/teacherPreview",
        name: "teacherAudit_teacherPreview",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_teacherPreview" */ "../views/teacher/teacherAudit/teacherPreview.vue"),
        meta: {
          title: '讲师概览',
          breadList: ['讲师', '讲师审核', '讲师概览'],
        },
      },
      /* 销售 */
      /* 商学院-前台 */
      /* 商学院-首页 */
      {
        path: "/sxyFront/home",
        name: "sxyFront_home",
        component: () =>
          import(/* webpackChunkName: "sxyFront_home" */ "../views/sales/saleStaff/sxyHome.vue"),
        meta: {
          title: '商学院-首页',
          keepAlive: true,
          breadList: ['销售', '商学院', '首页'],
        },
      },
      /* 商学院-前台-录音学习详情 */
      {
        path: "/sxyFront/sxyAudioLearnDetail",
        name: "sxyFront_sxyAudioLearnDetail",
        component: () =>
          import(/* webpackChunkName: "sxyAudioLearnDetail" */ "../views/sales/saleStaff/sxyAudioLearnDetail.vue"),
        meta: {
          title: '商学院-录音学习详情',
          breadList: ['销售', '商学院', '录音学习详情'],
        },
      },
      /* 商学院-前台-视频学习详情 */
      {
        path: "/sxyFront/sxyVideoLearnDetail",
        name: "sxyFront_sxyVideoLearnDetail",
        component: () =>
          import(/* webpackChunkName: "sxyVideoLearnDetail" */ "../views/sales/saleStaff/sxyVideoLearnDetail.vue"),
        meta: {
          title: '商学院-视频学习详情',
          breadList: ['销售', '商学院', '视频学习详情'],
        },
      },
      /* 商学院-前台-资料学习详情 */
      {
        path: "/sxyFront/sxyFileLearnDetail",
        name: "sxyFront_sxyFileLearnDetail",
        component: () =>
          import(/* webpackChunkName: "sxyFileLearnDetail" */ "../views/sales/saleStaff/sxyFileLearnDetail.vue"),
        meta: {
          title: '商学院-资料学习详情',
          breadList: ['销售', '商学院', '资料学习详情'],
        },
      },
      /* 商学院-前台-讨论中心详情 */
      {
        path: "/sxyFront/sxyDiscussionCenterDetail",
        name: "sxyFront_sxyDiscussionCenterDetail",
        component: () =>
          import(/* webpackChunkName: "sxyDiscussionCenterDetail" */ "../views/sales/saleStaff/sxyDiscussionCenterDetail.vue"),
        meta: {
          title: '商学院-讨论中心详情',
          breadList: ['销售', '商学院', '讨论中心详情'],
        },
      },
      /* 商学院-前台-升学政策详情 */
      {
        path: "/sxyFront/sxyNewsDetail",
        name: "sxyFront_sxyNewsDetail",
        component: () =>
          import(/* webpackChunkName: "sxyNewsDetail" */ "../views/sales/saleStaff/sxyNewsDetail.vue"),
        meta: {
          title: '商学院-升学政策详情',
          breadList: ['销售', '商学院', '升学政策详情'],
        },
      },
      /* 商学院-我的 */
      {
        path: "/sxyFront/my",
        name: "sxyFront_my",
        component: () =>
          import(/* webpackChunkName: "sxyFront_my" */ "../views/sales/saleStaff/sxyMy.vue"),
        meta: {
          title: '商学院-我的',
          keepAlive: true,
          breadList: ['销售', '商学院', '我的'],
        },
      },
      /* 销售 外呼审核员 */
      /* 外呼审核员 外呼数据审核 */
      {
        path: "/sales/saleAudit/useDataAudit",
        name: "dataManage_useDataAudit",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAudit" */ "../views/sales/dataManage/useDataAudit.vue"),
        meta: {
          title: '外呼数据审核',
          breadList: ['销售', '数据管理员', '外呼数据审核'],
        },
      },
      /* 外呼审核员 外呼数据审核详情 */
      {
        path: "/sales/saleAudit/useDataAuditDetail",
        name: "dataManage_useDataAuditDetail",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAuditDetail" */ "../views/sales/dataManage/useDataAuditDetail.vue"),
        meta: {
          title: '外呼数据审核详情',
          breadList: ['销售', '数据管理员', '外呼数据审核详情'],
          activeMenu: "/sales/saleAudit/useDataAudit"
        },
      },
      /* 销售专员 */
      {
        path: "/sales/saleStaff/dayPreview",
        name: "saleStaff_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleStaff_dayPreview" */ "../views/sales/saleStaff/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售专员', '每日看板'],
        },
      },
      {
        path: "/sales/saleStaff/handCallList",
        name: "saleStaff_handCallList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_handCallList" */ "../views/sales/saleStaff/handCallList.vue"),
        meta: {
          title: '拨打列表',
          breadList: ['销售', '销售专员', '拨打列表'],
        },
      },
      {
        path: "/sales/saleStaff/callHistoryList",
        name: "saleStaff_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售专员', '拨打记录'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeCallHistoryList",
        name: "saleStaff_parttimeCallHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_parttimeCallHistoryList" */ "../views/sales/saleStaff/parttimeCallHistoryList.vue"),
        meta: {
          title: '兼职拨打记录',
          breadList: ['销售', '销售专员', '兼职拨打记录'],
        },
      },
      {
        path: "/sales/saleStaff/testPage",
        name: "saleStaff_testPage",
        component: () =>
          import(/* webpackChunkName: "saleStaff_testPage" */ "../views/sales/saleStaff/testPage.vue"),
        meta: {
          title: '测试页面',
          breadList: ['销售', '销售专员', '测试页面'],
        },
      },
      {
        path: "/sales/saleStaff/userList",
        name: "saleStaff_userList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_userList" */ "../views/sales/saleStaff/userList.vue"),
        meta: {
          title: '签约学生',
          breadList: ['销售', '销售专员', '签约学生'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeIntention",
        name: "parttimeIntention",
        component: () =>
          import(/* webpackChunkName: "parttimeIntention" */ "../views/sales/saleStaff/parttimeIntention.vue"),
        meta: {
          title: '兼职意向',
          breadList: ['销售', '销售专员', '兼职意向'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeStatistics",
        name: "parttimeStatistics",
        component: () =>
          import(/* webpackChunkName: "parttimeStatistics" */ "../views/sales/saleStaff/parttimeStatistics.vue"),
        meta: {
          title: '兼职统计',
          breadList: ['销售', '销售专员', '兼职统计'],
        },
      },
      {
        path: "/sales/saleStaff/orderList",
        name: "saleStaff_orderList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_orderList" */ "../views/sales/saleStaff/orderList.vue"),
        meta: {
          title: '订单列表',
          breadList: ['销售', '销售专员', '订单列表'],
        },
      },
      {
        path: "/sales/saleStaff/intentionList",
        name: "saleStaff_intentionList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_intentionList" */ "../views/sales/saleStaff/intentionList.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售专员', '意向列表'],
        },
      },
      /* 销售专员 录音点评 */
      {
        path: "/sales/saleStaff/commentCallAudio",
        name: "commentCallAudio",
        component: () =>
          import(/* webpackChunkName: "dataManage_commentCallAudio" */ "../views/sales/dataManage/commentCallAudio.vue"),
        meta: {
          title: '录音点评',
          keepAlive: true,
          breadList: ['销售', '销售专员', '录音点评'],
        },
      },
      {
        path: "/sales/saleStaff/callAudioStudy",
        name: "callAudioStudy",
        component: () =>
          import(/* webpackChunkName: "saleStaff_callAudioStudy" */ "../views/sales/saleStaff/callAudioStudy.vue"),
        meta: {
          title: '录音学习',
          keepAlive: true,
          breadList: ['销售', '销售专员', '录音学习'],
        },
      },
      {
        path: "/sales/saleStaff/myTryCourse",
        name: "saleStaff_myTryCourse",
        component: () =>
          import(/* webpackChunkName: "saleStaff_myTryCourse" */ "../views/teachBusiness/teachBusinessAttache/myTryCourse.vue"),
        meta: {
          title: '我的试听',
          breadList: ['销售', '销售专员', '我的试听'],
        },
      },
      {
        path: "/sales/saleStaff/monitorClass",
        name: "saleStaff_monitorClass",
        component: () =>
          import(/* webpackChunkName: "saleStaff_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['销售', '销售专员', '监课管理'],
        },
      },
      {
        path: "/sales/saleStaff/playbackList",
        name: "saleStaff_playbackList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['销售', '销售专员', '回放管理'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeList",
        name: "saleStaff_parttimeList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_parttimeList" */ "../views/sales/saleStaff/parttimeList.vue"),
        meta: {
          title: '兼职列表',
          breadList: ['销售', '销售专员', '兼职列表'],
        },
      },
      {
        path: "/sales/saleStaff/publicUseList",
        name: "saleStaff_publicUseList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_publicUseList" */ "../views/sales/saleStaff/publicUseList.vue"),
        meta: {
          title: '公共兼职数据池',
          breadList: ['销售', '销售专员', '公共兼职数据池'],
        },
      },
      {
        path: "/sales/saleStaff/openSeaList",
        name: "saleStaff_openSeaList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_openSeaList" */ "../views/sales/saleStaff/openSeaList.vue"),
        meta: {
          title: '公海',
          breadList: ['销售', '销售专员', '公海'],
        },
      },
      /* 销售主管 */
      {
        path: "/sales/saleLeader/dayPreview",
        name: "saleLeader_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_dayPreview" */ "../views/sales/saleLeader/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售主管', '每日看板'],
        },
      },
      /* 销售主管-意向概览(意向列表) */
      {
        path: "/sales/saleLeader/intentionPreview",
        name: "saleLeader_intentionPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_intentionPreview" */ "../views/sales/saleLeader/intentionPreview.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售主管', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/intentionStatistics",
        name: "intentionStatistics",
        component: () =>
          import(/* webpackChunkName: "intentionStatistics" */ "../views/sales/saleLeader/intentionStatistics.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售主管', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/userList",
        name: "saleLeader_userList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_userList" */ "../views/sales/saleStaff/userList.vue"),
        meta: {
          title: '签约列表',
          breadList: ['销售', '销售主管', '签约列表'],
        },
      },
      {
        path: "/sales/saleLeader/saleStatistics",
        name: "saleStatistics",
        component: () =>
          import(/* webpackChunkName: "saleStatistics" */ "../views/sales/saleLeader/saleStatistics.vue"),
        meta: {
          title: '销售统计',
          breadList: ['销售', '销售主管', '销售统计'],
        },
      },
      {
        path: "/sales/saleLeader/weekReport",
        name: "saleLeader_dweekReport",
        component: () =>
          import(/* webpackChunkName: "saleLeader_weekReport" */ "../views/sales/saleLeader/weekReport.vue"),
        meta: {
          title: '本周报表',
          breadList: ['销售', '销售主管', '本周报表'],
        },
      },
      {
        path: "/sales/saleLeader/negotiationOrderList",
        name: "saleLeader_negotiationOrderList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_negotiationOrderList" */ "../views/sales/saleLeader/negotiationOrderList.vue"),
        meta: {
          title: '谈单分析',
          breadList: ['销售', '销售主管', '谈单分析'],
        },
      },
      /* 销售主管 销售总监 外边多了一层 */
      {
        path: "/sales/saleLeader/callHistoryList",
        name: "saleLeader_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售主管', '拨打记录'],
          //activeMenu: "/sales/saleLeader/callHistoryPreview"
        },
      },
      /* 销售主管 销售总监 多的拨打记录统计 */
      {
        path: "/sales/saleLeader/callHistoryPreview",
        name: "saleLeader_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_callHistoryPreview" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '拨打统计',
          breadList: ['销售', '销售主管', '拨打统计'],
        },
      },
      /* 销售主管 录音点评 */
      {
        path: "/sales/saleLeader/commentCallAudio",
        name: "commentCallAudio",
        component: () =>
          import(/* webpackChunkName: "dataManage_commentCallAudio" */ "../views/sales/dataManage/commentCallAudio.vue"),
        meta: {
          title: '录音点评',
          keepAlive: true,
          breadList: ['销售', '销售主管', '录音点评'],
        },
      },
      /* 销售总监-每日看板 */
      {
        path: "/sales/saleMajordomo/dayPreview",
        name: "saleMajordomo_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_dayPreview" */ "../views/sales/saleLeader/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售总监', '每日看板'],
        },
      },
      /* 销售主管-意向概览(意向列表) */
      {
        path: "/sales/saleMajordomo/intentionPreview",
        name: "saleLeader_intentionPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_intentionPreview" */ "../views/sales/saleLeader/intentionPreview.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售总监', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/salePersonalList",
        name: "saleLeader_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '销售主管', '销售个人库'],
        },
      },
      {
        path: "/sales/saleLeader/orderList",
        name: "saleLeader_orderList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_orderList" */ "../views/teachBusiness/teachBusinessAttache/orderList.vue"),
        meta: {
          title: '部门订单',
          breadList: ['销售', '销售主管', '部门订单'],
        },
      },
      {
        path: "/sales/saleLeader/monitorClass",
        name: "saleLeader_monitorClass",
        component: () =>
          import(/* webpackChunkName: "saleLeader_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['销售', '销售主管', '监课管理'],
        },
      },
      {
        path: "/sales/saleLeader/playbackList",
        name: "saleLeader_playbackList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['销售', '销售主管', '回放管理'],
        },
      },
      {
        path: "/sales/saleLeader/arrangeTryCourse",
        name: "saleLeader_arrangeTryCourse",
        component: () =>
          import(/* webpackChunkName: "saleLeader_arrangeTryCourse" */ "../views/teachBusiness/teachBusinessAttache/arrangeTryCourse.vue"),
        meta: {
          title: '排试听课',
          breadList: ['销售', '销售主管', '排试听课'],
        },
      },
      {
        path: "/sales/saleLeader/myTryCourse",
        name: "saleLeader_myTryCourse",
        component: () =>
          import(/* webpackChunkName: "saleLeader_myTryCourse" */ "../views/teachBusiness/teachBusinessAttache/myTryCourse.vue"),
        meta: {
          title: '试听列表',
          breadList: ['销售', '销售主管', '试听列表'],
        },
      },
      /* 我的兼职 */
      {
        path: "/sales/myParttime/userList",
        name: "myParttime_userList",
        component: () =>
          import(/* webpackChunkName: "myParttime_userList" */ "../views/sales/myParttime/userList.vue"),
        meta: {
          title: '外呼列表',
          breadList: ['销售', '我的兼职', '外呼列表'],
        },
      },
      {
        path: "/sales/myParttime/intentionList",
        name: "myParttime_intentionList",
        component: () =>
          import(/* webpackChunkName: "myParttime_intentionList" */ "../views/sales/myParttime/intentionList.vue"),
        meta: {
          title: '意向表',
          breadList: ['销售', '我的兼职', '意向表'],
        },
      },
      {
        path: "/sales/myParttime/handCallList",
        name: "myParttime_handCallList",
        component: () =>
          import(/* webpackChunkName: "myParttime_handCallList" */ "../views/sales/myParttime/handCallList.vue"),
        meta: {
          title: '拨打列表',
          breadList: ['销售', '我的兼职', '拨打列表'],
        },
      },
      {
        path: "/sales/myParttime/callHistoryList",
        name: "myParttime_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "myParttime_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '我的兼职', '拨打记录'],
        },
      },
      /* 数据管理员 */
      /* 商学院录音管理 */
      {
        path: "/sales/sxyAudioManage",
        name: "sxyAudioManage",
        component: () =>
          import(/* webpackChunkName: "sxyAudioManage" */ "../views/sales/dataManage/sxyAudioManage.vue"),
        meta: {
          title: '商学院-录音学习管理',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '商学院-录音学习管理'],
        },
      },
      /* 商学院视频管理 */
      {
        path: "/sales/sxyVideoManage",
        name: "sxyVideoManage",
        component: () =>
          import(/* webpackChunkName: "sxyVideoManage" */ "../views/sales/dataManage/sxyVideoManage.vue"),
        meta: {
          title: '商学院-视频学习管理',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '商学院-视频学习管理'],
        },
      },
      /* 商学院资料管理 */
      {
        path: "/sales/sxyMaterialManage",
        name: "sxyMaterialManage",
        component: () =>
          import(/* webpackChunkName: "sxyMaterialManage" */ "../views/sales/dataManage/sxyMaterialManage.vue"),
        meta: {
          title: '商学院-学习资料管理',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '商学院-学习资料管理'],
        },
      },
      /* 商学院讨论中心 */
      {
        path: "/sales/sxyDiscussionCenterManage",
        name: "sxyDiscussionCenterManage",
        component: () =>
          import(/* webpackChunkName: "sxyDiscussionCenterManage" */ "../views/sales/dataManage/sxyDiscussionCenterManage.vue"),
        meta: {
          title: '商学院-讨论中心管理',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '商学院-讨论中心管理'],
        },
      },
      /* 商学院资讯管理 */
      {
        path: "/sales/sxyNewsManage",
        name: "sxyNewsManage",
        component: () =>
          import(/* webpackChunkName: "sxyNewsManage" */ "../views/sales/dataManage/sxyNewsManage.vue"),
        meta: {
          title: '商学院-资讯管理',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '商学院-资讯管理'],
        },
      },
      /* 发送短信 */
      {
        path: "/sales/dataManage/sendMessageList",
        name: "successCaseList",
        component: () =>
          import(/* webpackChunkName: "sendMessageList" */ "../views/sales/dataManage/sendMessageList.vue"),
        meta: {
          title: '发送短信',
          breadList: ['销售', '数据管理员', '发送短信'],
        },
      },
      /* 商圈管理 */
      {
        path: "/sales/dataManage/businessAreaList",
        name: "businessAreaList",
        component: () =>
          import(/* webpackChunkName: "businessAreaList" */ "../views/sales/dataManage/businessAreaList.vue"),
        meta: {
          title: '商圈管理',
          breadList: ['销售', '数据管理员', '商圈管理'],
        },
      },
      /* 检测数据管理 */
      {
        path: "/sales/dataManage/checkDataList",
        name: "checkDataList",
        component: () =>
          import(/* webpackChunkName: "checkDataList" */ "../views/sales/dataManage/checkDataList.vue"),
        meta: {
          title: '检测数据记录',
          breadList: ['销售', '数据管理员', '检测数据记录'],
        },
      },
      /* 短信发送记录 */
      {
        path: "/sales/dataManage/sendMessageRecord",
        name: "sendMessageRecord",
        component: () =>
          import(/* webpackChunkName: "sendMessageList" */ "../views/sales/dataManage/sendMessageRecord.vue"),
        meta: {
          title: '短信记录',
          breadList: ['销售', '数据管理员', '短信记录'],
        },
      },
      /* 成功案例 */
      {
        path: "/sales/dataManage/successCaseList",
        name: "successCaseList",
        component: () =>
          import(/* webpackChunkName: "successCaseList" */ "../views/sales/dataManage/successCaseList.vue"),
        meta: {
          title: '成功案例',
          breadList: ['销售', '数据管理员', '成功案例'],
        },
      },
      /* 学校管理 */
      {
        path: "/sales/dataManage/schoolManage",
        name: "schoolManage",
        component: () =>
          import(/* webpackChunkName: "schoolManage" */ "../views/sales/dataManage/schoolManage.vue"),
        meta: {
          title: '学校管理',
          breadList: ['销售', '数据管理员', '学校管理'],
        },
      },
      /* 通知管理 */
      {
        path: "/sales/dataManage/noticeList",
        name: "noticeList",
        component: () =>
          import(/* webpackChunkName: "noticeList" */ "../views/sales/dataManage/noticeList.vue"),
        meta: {
          title: '通知管理',
          breadList: ['销售', '数据管理员', '通知管理'],
        },
      },
      {
        path: "/sales/dataManage/blackList",
        name: "blackList",
        component: () =>
          import(/* webpackChunkName: "blackList" */ "../views/sales/dataManage/blackList.vue"),
        meta: {
          title: '黑名单',
          breadList: ['销售', '数据管理员', '黑名单'],
        },
      },
      {
        path: "/sales/dataManage/callLogList",
        name: "callLogList",
        component: () =>
          import(/* webpackChunkName: "callLogList" */ "../views/sales/dataManage/callLogList.vue"),
        meta: {
          title: '呼出记录',
          breadList: ['销售', '数据管理员', '呼出记录'],
        },
      },
      {
        path: "/sales/dataManage/callPool",
        name: "callPool",
        component: () =>
          import(/* webpackChunkName: "callPool" */ "../views/sales/dataManage/callPool.vue"),
        meta: {
          title: '总电话池',
          breadList: ['销售', '数据管理员', '总电话池'],
        },
      },
      {
        path: "/sales/dataManage/firstHandCallPool",
        name: "firstHandCallPool",
        component: () =>
          import(/* webpackChunkName: "firstHandCallPool" */ "../views/sales/dataManage/firstHandCallPool.vue"),
        meta: {
          title: '新数据池子',
          breadList: ['销售', '数据管理员', '新数据池子'],
        },
      },
      {
        path: "/sales/dataManage/successHandCallPool",
        name: "successHandCallPool",
        component: () =>
          import(/* webpackChunkName: "successHandCallPool" */ "../views/sales/dataManage/successHandCallPool.vue"),
        meta: {
          title: '真实数据池子',
          breadList: ['销售', '数据管理员', '真实数据池子'],
        },
      },
      {
        path: "/sales/dataManage/rubbishList",
        name: "rubbishList",
        component: () =>
          import(/* webpackChunkName: "rubbishList" */ "../views/sales/dataManage/rubbishList.vue"),
        meta: {
          title: '垃圾库',
          breadList: ['销售', '数据管理员', '垃圾库'],
        },
      },
      {
        path: "/sales/dataManage/noWanderList",
        name: "noWanderList",
        component: () =>
          import(/* webpackChunkName: "noWanderList" */ "../views/sales/dataManage/noWanderList.vue"),
        meta: {
          title: '禁止轮转列表',
          breadList: ['销售', '数据管理员', '禁止轮转列表'],
        },
      },
      {
        path: "/sales/dataManage/openSeaList",
        name: "dataManage_openSeaList",
        component: () =>
          import(/* webpackChunkName: "dataManage_openSeaList" */ "../views/sales/dataManage/openSeaList.vue"),
        meta: {
          title: '公海',
          breadList: ['销售', '数据管理员', '公海'],
        },
      },
      /* 数据管理员 外呼数据审核 */
      {
        path: "/sales/dataManage/useDataAudit",
        name: "dataManage_useDataAudit",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAudit" */ "../views/sales/dataManage/useDataAudit.vue"),
        meta: {
          title: '外呼数据审核',
          breadList: ['销售', '数据管理员', '外呼数据审核'],
        },
      },
      /* 数据管理员 外呼数据审核详情 */
      {
        path: "/sales/dataManage/useDataAuditDetail",
        name: "dataManage_useDataAuditDetail",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAuditDetail" */ "../views/sales/dataManage/useDataAuditDetail.vue"),
        meta: {
          title: '外呼数据审核详情',
          breadList: ['销售', '数据管理员', '外呼数据审核详情'],
          activeMenu: "/sales/dataManage/useDataAudit"
        },
      },
      {
        path: "/sales/dataManage/publicUseList",
        name: "dataManage_publicUseList",
        component: () =>
          import(/* webpackChunkName: "dataManage_publicUseList" */ "../views/sales/dataManage/publicUseList.vue"),
        meta: {
          title: '公共兼职数据池',
          breadList: ['销售', '数据管理员', '公共兼职数据池'],
        },
      },
      {
        path: "/sales/dataManage/importListStatistics",
        name: "importListStatistics",
        component: () =>
          import(/* webpackChunkName: "importListStatistics" */ "../views/sales/dataManage/importListStatistics.vue"),
        meta: {
          title: '数据统计',
          breadList: ['销售', '数据管理员', '数据统计'],
        },
      },
      {
        path: "/sales/dataManage/importBatchAlloc",
        name: "importBatchAlloc",
        component: () =>
          import(/* webpackChunkName: "importBatchAlloc" */ "../views/sales/dataManage/importBatchAlloc.vue"),
        meta: {
          title: '数据分配',
          activeMenu: '/sales/dataManage/importListStatistics',
          breadList: ['销售', '数据管理员', '数据分配'],
        },
      },
      /* 公共兼职拨打记录 */
      {
        path: "/sales/dataManage/parttimeCallHistoryList",
        name: "saleMajordomo_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '公共兼职拨打记录',
          breadList: ['销售', '数据管理员', '公共兼职拨打记录'],
          //activeMenu: "/sales/saleMajordomo/callHistoryPreview"
        },
      },
      /* 数据管理员 公共兼职拨打统计 */
      {
        path: "/sales/dataManage/parttimeCallHistoryPreview",
        name: "saleMajordomo_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '公共兼职拨打统计',
          breadList: ['销售', '数据管理员', '公共兼职拨打统计'],
        },
      },
      /* 数据管理员 外边多了一层 */
      {
        path: "/sales/dataManage/callHistoryList",
        name: "saleMajordomo_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '数据管理员', '拨打记录'],
          //activeMenu: "/sales/saleMajordomo/callHistoryPreview"
        },
      },
      /* 数据管理员 多的拨打记录统计 */
      {
        path: "/sales/dataManage/callHistoryPreview",
        name: "saleMajordomo_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '拨打统计',
          breadList: ['销售', '数据管理员', '拨打统计'],
        },
      },
      /* 数据管理员 录音点评 */
      {
        path: "/sales/dataManage/commentCallAudio",
        name: "commentCallAudio",
        component: () =>
          import(/* webpackChunkName: "dataManage_commentCallAudio" */ "../views/sales/dataManage/commentCallAudio.vue"),
        meta: {
          title: '录音点评',
          keepAlive: true,
          breadList: ['销售', '数据管理员', '录音点评'],
        },
      },
      // 录音点评详情
      {
        path: "/sales/dataManage/audioCommentDetail",
        name: "dataManage_audioCommentDetail",
        component: () =>
          import(/* webpackChunkName: "dataManage_audioCommentDetail" */ "../views/sales/dataManage/audioCommentDetail.vue"),
        meta: {
          title: '录音点评',
          breadList: ['销售', '数据管理员', '录音点评'],
        },
      },
      {
        path: "/sales/dataManage/otherSourceList",
        name: "dataManage_otherSourceList",
        component: () =>
          import(/* webpackChunkName: "dataManage_otherSourceList" */ "../views/sales/dataManage/otherSourceList.vue"),
        meta: {
          title: '其他来源',
          breadList: ['销售', '数据管理员', '其他来源'],
        },
      },
      {
        path: "/sales/dataManage/otherSourceAlloc",
        name: "dataManage_otherSourceAlloc",
        component: () =>
          import(/* webpackChunkName: "dataManage_otherSourceAlloc" */ "../views/sales/dataManage/otherSourceAlloc.vue"),
        meta: {
          title: '重复数据',
          activeMenu: '/sales/dataManage/otherSourceList',
          breadList: ['销售', '数据管理员', '重复数据'],
        },
      },
      {
        path: "/sales/dataManage/otherSourceDetail",
        name: "dataManage_otherSourceDetail",
        component: () =>
          import(/* webpackChunkName: "dataManage_otherSourceDetail" */ "../views/sales/dataManage/otherSourceDetail.vue"),
        meta: {
          title: '其他来源详情',
          activeMenu: '/sales/dataManage/otherSourceList',
          breadList: ['销售', '数据管理员', '其他来源详情'],
        },
      },
      {
        path: "/sales/dataManage/importList",
        name: "dataManage_importList",
        component: () =>
          import(/* webpackChunkName: "dataManage_importList" */ "../views/sales/dataManage/importList.vue"),
        meta: {
          title: '数据列表',
          breadList: ['销售', '数据管理员', '数据列表'],
        },
      },
      {
        path: "/sales/dataManage/importList/importDetail",
        name: "importDetail",
        component: () =>
          import(/* webpackChunkName: "importDetail" */ "../views/sales/dataManage/importDetail.vue"),
        meta: {
          title: '数据详情',
          activeMenu: '/sales/dataManage/importList',
          breadList: ['销售', '数据管理员', '数据列表', '数据详情'],
        },
      },
      {
        path: "/sales/dataManage/callTaskList",
        name: "dataManage_callTaskList",
        component: () =>
          import(/* webpackChunkName: "dataManage_callTaskList" */ "../views/sales/dataManage/callTaskList.vue"),
        meta: {
          title: '任务列表',
          breadList: ['销售', '数据管理员', '任务列表'],
        },
      },
      {
        path: "/sales/dataManage/phoneBind",
        name: "dataManage_phoneBind",
        component: () =>
          import(/* webpackChunkName: "dataManage_phoneBind" */ "../views/sales/dataManage/phoneBind.vue"),
        meta: {
          title: '手机号绑定',
          breadList: ['销售', '数据管理员', '手机号绑定'],
        },
      },
      {
        path: "/sales/dataManage/callMonitor",
        name: "dataManage_callMonitor",
        component: () =>
          import(/* webpackChunkName: "dataManage_callMonitor" */ "../views/sales/dataManage/callMonitor.vue"),
        meta: {
          title: '坐席监控',
          breadList: ['销售', '数据管理员', '坐席监控'],
        },
      },
      {
        path: "/sales/dataManage/callAccountManage",
        name: "dataManage_callAccountManage",
        component: () =>
          import(/* webpackChunkName: "dataManage_callAccountManage" */ "../views/sales/dataManage/callAccountManage.vue"),
        meta: {
          title: '坐席分配',
          breadList: ['销售', '数据管理员', '坐席分配'],
        },
      },
      {
        path: "/sales/dataManage/callPlatformSet",
        name: "dataManage_callPlatformSet",
        component: () =>
          import(/* webpackChunkName: "dataManage_callPlatformSet" */ "../views/sales/dataManage/callPlatformSet.vue"),
        meta: {
          title: '电话设置',
          breadList: ['销售', '数据管理员', '电话设置'],
        },
      },
      {
        path: "/sales/dataManage/salePersonalList",
        name: "dataManage_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "dataManage_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '数据管理员', '销售个人库'],
        },
      },
      {
        path: "/sales/dataManage/biYeShengList",
        name: "dataManage_biYeShengList",
        component: () =>
          import(/* webpackChunkName: "dataManage_biYeShengList" */ "../views/sales/dataManage/biYeShengList.vue"),
        meta: {
          title: '毕业生列表',
          breadList: ['销售', '数据管理员', '毕业生列表'],
        },
      },
      {
        path: "/sales/dataManage/callTaskList/callTaskDetail",
        name: "callDetail",
        component: () =>
          import(/* webpackChunkName: "callDetail" */ "../views/sales/dataManage/callTaskDetail.vue"),
        meta: {
          title: '任务详情',
          activeMenu: '/sales/dataManage/callTaskList',
          breadList: ['销售', '数据管理员', '任务列表', '任务详情'],
        },
      },
      /* 销售总监 */
      {
        path: "/sales/saleMajordomo/userList",
        name: "major_userList",
        component: () =>
          import(/* webpackChunkName: "major_userList" */ "../views/sales/saleStaff/userList.vue"),
        meta: {
          title: '签约列表',
          breadList: ['销售', '销售总监', '签约列表'],
        },
      },
      {
        path: "/sales/saleMajordomo/intentionStatistics",
        name: "major_intentionStatistics",
        component: () =>
          import(/* webpackChunkName: "saleSectionIntertion" */ "../views/sales/saleLeader/intentionStatistics.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售总监', '意向列表'],
        },
      },
      {
        path: "/sales/saleMajordomo/saleSectionIntertion",
        name: "saleSectionIntertion",
        component: () =>
          import(/* webpackChunkName: "saleSectionIntertion" */ "../views/sales/saleMajordomo/saleSectionIntertion.vue"),
        meta: {
          title: '部门意向',
          breadList: ['销售', '销售总监', '部门意向'],
        },
      },
      {
        path: "/sales/saleMajordomo/saleStaffPerformanceSet",
        name: "saleStaffPerformanceSet",
        component: () =>
          import(/* webpackChunkName: "saleStaffPerformanceSet" */ "../views/sales/saleMajordomo/saleStaffPerformanceSet.vue"),
        meta: {
          title: '员工业绩设置',
          breadList: ['销售', '销售总监', '员工业绩设置'],
        },
      },
      /* 销售总监谈单分析 */
      {
        path: "/sales/saleMajordomo/negotiationOrderList",
        name: "saleLeader_negotiationOrderList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_negotiationOrderList" */ "../views/sales/saleLeader/negotiationOrderList.vue"),
        meta: {
          title: '谈单分析',
          breadList: ['销售', '销售总监', '谈单分析'],
        },
      },
      {
        path: "/sales/saleMajordomo/parttimeCallHistoryList",
        name: "saleMajordomo_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '公共兼职拨打记录',
          breadList: ['销售', '销售总监', '公共兼职拨打记录'],
        },
      },
      /* 销售总监 外呼数据审核 */
      {
        path: "/sales/saleMajordomo/useDataAudit",
        name: "dataManage_useDataAudit",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAudit" */ "../views/sales/dataManage/useDataAudit.vue"),
        meta: {
          title: '外呼数据审核',
          breadList: ['销售', '销售总监', '外呼数据审核'],
        },
      },
      /* 销售总监 外呼数据审核详情 */
      {
        path: "/sales/saleMajordomo/useDataAuditDetail",
        name: "dataManage_useDataAuditDetail",
        component: () =>
          import(/* webpackChunkName: "dataManage_useDataAuditDetail" */ "../views/sales/dataManage/useDataAuditDetail.vue"),
        meta: {
          title: '外呼数据审核详情',
          breadList: ['销售', '数据管理员', '外呼数据审核详情'],
          activeMenu: "/sales/saleMajordomo/useDataAudit"
        },
      },
      /* 销售总监 公共兼职拨打统计 */
      {
        path: "/sales/saleMajordomo/parttimeCallHistoryPreview",
        name: "saleMajordomo_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '公共兼职拨打统计',
          breadList: ['销售', '销售总监', '公共兼职拨打统计'],
        },
      },
      /* 销售主管 销售总监 外边多了一层 */
      {
        path: "/sales/saleMajordomo/callHistoryList",
        name: "saleMajordomo_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售总监', '拨打记录'],
          //activeMenu: "/sales/saleMajordomo/callHistoryPreview"
        },
      },
      /* 销售主管 销售总监 多的拨打记录统计 */
      {
        path: "/sales/saleMajordomo/callHistoryPreview",
        name: "saleMajordomo_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '拨打统计',
          breadList: ['销售', '销售总监', '拨打统计'],
        },
      },
      /* 销售总监 录音点评 */
      {
        path: "/sales/saleMajordomo/commentCallAudio",
        name: "commentCallAudio",
        component: () =>
          import(/* webpackChunkName: "dataManage_commentCallAudio" */ "../views/sales/dataManage/commentCallAudio.vue"),
        meta: {
          title: '录音点评',
          keepAlive: true,
          breadList: ['销售', '销售总监', '录音点评'],
        },
      },
      {
        path: "/sales/saleMajordomo/salePersonalList",
        name: "saleMajordomo_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '销售总监', '销售个人库'],
        },
      },
      /* 财务 */
      {
        path: "/finance/dayPreview",
        name: "finance_dayPreview",
        component: () =>
          import(/* webpackChunkName: "finance_dayPreview" */ "../views/finance/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['财务', '每日看板'],
        },
      },
      {
        path: "/finance/classConsumeStatistics",
        name: "finance_classConsumeStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_classConsumeStatistics" */ "../views/finance/classConsumeStatistics.vue"),
        meta: {
          title: '课酬课耗统计',
          breadList: ['财务', '课酬课耗统计'],
        },
      },
      {
        path: "/finance/orderList",
        name: "finance_orderList",
        component: () =>
          import(/* webpackChunkName: "finance_orderList" */ "../views/finance/orderList.vue"),
        meta: {
          title: '充值审核',
          breadList: ['财务', '充值审核'],
        },
      },
      {
        path: "/finance/tryClassList",
        name: "finance_tryClassList",
        component: () =>
          import(/* webpackChunkName: "finance_tryClassList" */ "../views/finance/tryClassList.vue"),
        meta: {
          title: '试听跟班审核',
          breadList: ['财务', '试听跟班审核'],
        },
      },
      {
        path: "/finance/incomeStatistics",
        name: "finance_incomeStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_incomeStatistics" */ "../views/finance/incomeStatistics.vue"),
        meta: {
          title: '收入统计',
          breadList: ['财务', '收入统计'],
        },
      },
      {
        path: "/finance/classhourStatistics",
        name: "finance_classhourStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_classhourStatistics" */ "../views/finance/classhourStatistics.vue"),
        meta: {
          title: '课时统计',
          breadList: ['财务', '课时统计'],
        },
      },
      {
        path: "/finance/refundList",
        name: "finance_refundList",
        component: () =>
          import(/* webpackChunkName: "finance_refundList" */ "../views/finance/refundList.vue"),
        meta: {
          title: '退费审核',
          breadList: ['财务', '退费审核'],
        },
      },
      {
        path: "/finance/teacherWageList",
        name: "finance_teacherWageList",
        component: () =>
          import(/* webpackChunkName: "finance_teacherWageList" */ "../views/finance/teacherWageList.vue"),
        meta: {
          title: '讲师课酬',
          breadList: ['财务', '讲师课酬'],
        },
      },
      {
        path: "/finance/studentClasshour",
        name: "finance_studentClasshour",
        component: () =>
          import(/* webpackChunkName: "finance_studentClasshour" */ "../views/finance/studentClasshour.vue"),
        meta: {
          title: '学员课时',
          breadList: ['财务', '学员课时'],
        },
      },
      {
        path: "/finance/classPayList",
        name: "finance_classPayList",
        component: () =>
          import(/* webpackChunkName: "finance_classPayList" */ "../views/finance/classPayList.vue"),
        meta: {
          title: '课酬设置',
          breadList: ['财务', '课酬设置'],
        },
      },
      {
        path: "/finance/salePerformanceList",
        name: "finance_salePerformanceList",
        component: () =>
          import(/* webpackChunkName: "finance_salePerformanceList" */ "../views/finance/salePerformanceList.vue"),
        meta: {
          title: '销售业绩',
          breadList: ['财务', '销售业绩'],
        },
      },
      {
        path: "/finance/teachBusinessPerformanceList",
        name: "finance_teachBusinessPerformanceList",
        component: () =>
          import(/* webpackChunkName: "finance_teachBusinessPerformanceList" */ "../views/finance/teachBusinessPerformanceList.vue"),
        meta: {
          title: '教务业绩',
          breadList: ['财务', '教务业绩'],
        },
      },
      {
        path: "/finance/studentPackageList",
        name: "finance_studentPackageList",
        component: () =>
          import(/* webpackChunkName: "finance_studentPackageList" */ "../views/finance/studentPackageList.vue"),
        meta: {
          title: '学员套餐',
          breadList: ['财务', '学员套餐'],
        },
      },
      {
        path: "/finance/remainClasshourList",
        name: "finance_remainClasshourList",
        component: () =>
          import(/* webpackChunkName: "finance_remainClasshourList" */ "../views/finance/remainClasshourList.vue"),
        meta: {
          title: '剩余课时',
          breadList: ['财务', '剩余课时'],
        },
      },
      {
        path: "/finance/teacherWorkPreview",
        name: "finance_teacherWorkPreview",
        component: () =>
          import(/* webpackChunkName: "finance_teacherWorkPreview" */ "../views/finance/teacherWorkPreview.vue"),
        meta: {
          title: '教师概览',
          breadList: ['财务', '教师概览'],
        },
      },
      /* 教务 */
      /* 教务专员 */
      {
        path: "/teachBusiness/teachBusinessAttache/dayPreview",
        name: "teachBusinessAttache_dayPreview",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_dayPreview" */ "../views/teachBusiness/teachBusinessAttache/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['教务', '教务专员', '每日看板'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/dayPreviewDetail",
        name: "teachBusinessAttache_dayPreviewDetail",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_dayPreviewDetail" */ "../views/teachBusiness/teachBusinessAttache/dayPreviewDetail.vue"),
        meta: {
          title: '每日看板',
          activeMenu: '/teachBusiness/teachBusinessAttache/dayPreview',
          breadList: ['教务', '教务专员', '每日看板', "看板信息"],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/myIncome",
        name: "teachBusinessAttache_myIncome",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_myIncome" */ "../views/teachBusiness/teachBusinessAttache/myIncome.vue"),
        meta: {
          title: '我的收入',
          breadList: ['教务', '教务专员', '我的收入'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/addStudent",
        name: "teachBusinessAttache_addStudent",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_addStudent" */ "../views/teachBusiness/teachBusinessAttache/addStudent.vue"),
        meta: {
          title: '新增学员',
          breadList: ['教务', '教务专员', '新增学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/myStudentList",
        name: "teachBusinessAttache_myStudentList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_myStudentList" */ "../views/teachBusiness/teachBusinessAttache/myStudentList.vue"),
        meta: {
          title: '我的学员',
          breadList: ['教务', '教务专员', '我的学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/courseList",
        name: "teachBusinessAttache_courseList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_courseList" */ "../views/teachBusiness/teachBusinessAttache/courseList.vue"),
        meta: {
          title: '课表管理',
          breadList: ['教务', '教务专员', '课表管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/monitorClass",
        name: "teachBusinessAttache_monitorClass",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['教务', '教务专员', '监课管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/playbackList",
        name: "teachBusinessAttache_playbackList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['教务', '教务专员', '回放管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/classList",
        name: "teachBusinessAttache_classList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_classList" */ "../views/teachBusiness/teachBusinessAttache/classList.vue"),
        meta: {
          title: '班级管理',
          breadList: ['教务', '教务专员', '班级管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/courseRecord",
        name: "teachBusinessAttache_courseRecord",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_courseRecord" */ "../views/teachBusiness/teachBusinessAttache/courseRecord.vue"),
        meta: {
          title: '上课记录',
          breadList: ['教务', '教务专员', '上课记录'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/orderList",
        name: "teachBusinessAttache_orderList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_orderList" */ "../views/teachBusiness/teachBusinessAttache/orderList.vue"),
        meta: {
          title: '我的订单',
          breadList: ['教务', '教务专员', '我的订单'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/myTryCourse",
        name: "teachBusinessAttache_myTryCourse",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_myTryCourse" */ "../views/teachBusiness/teachBusinessAttache/myTryCourse.vue"),
        meta: {
          title: '我的试听',
          breadList: ['教务', '教务专员', '我的试听'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/arrangeTryCourse",
        name: "teachBusinessAttache_arrangeTryCourse",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_arrangeTryCourse" */ "../views/teachBusiness/teachBusinessAttache/arrangeTryCourse.vue"),
        meta: {
          title: '排试听课',
          breadList: ['教务', '教务专员', '排试听课'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/packageList",
        name: "teachBusinessAttache_packageList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_packageList" */ "../views/teachBusiness/teachBusinessAttache/packageList.vue"),
        meta: {
          title: '套餐添加',
          breadList: ['教务', '教务专员', '套餐添加'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/teacherListFormal",
        name: "teachBusinessAttache_teacherListFormal",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_teacherListFormal" */ "../views/teachBusiness/teachBusinessAttache/teacherListFormal.vue"),
        meta: {
          title: '教师管理-正式表',
          breadList: ['教务', '教务专员', '教师管理-正式表'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/teacherListReserve",
        name: "teachBusinessAttache_teacherListReserve",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_teacherListReserve" */ "../views/teachBusiness/teachBusinessAttache/teacherListReserve.vue"),
        meta: {
          title: '教师管理-储备表',
          breadList: ['教务', '教务专员', '教师管理-储备表'],
        },
      },
      /* 教务主管 */
      {
        path: "/teachBusiness/teachBusinessSupervisor/dayPreview",
        name: "teachBusinessSupervisor_dayPreview",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_dayPreview" */ "../views/teachBusiness/teachBusinessAttache/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['教务', '教务主管', '每日看板'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/dayPreviewDetail",
        name: "teachBusinessSupervisor_dayPreviewDetail",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_dayPreviewDetail" */ "../views/teachBusiness/teachBusinessAttache/dayPreviewDetail.vue"),
        meta: {
          title: '每日看板',
          activeMenu: '/teachBusiness/teachBusinessSupervisor/dayPreview',
          breadList: ['教务', '教务主员', '每日看板', "看板信息"],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/studentList",
        name: "teachBusinessSupervisor_studentList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_studentList" */ "../views/teachBusiness/teachBusinessSupervisor/studentList.vue"),
        meta: {
          title: '部门学员',
          breadList: ['教务', '教务主管', '部门学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/courseList",
        name: "teachBusinessSupervisor_courseList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_courseList" */ "../views/teachBusiness/teachBusinessAttache/courseList.vue"),
        meta: {
          title: '部门课表',
          breadList: ['教务', '教务主管', '部门课表'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/monitorClass",
        name: "teachBusinessSupervisor_monitorClass",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['教务', '教务主管', '监课管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/playbackList",
        name: "teachBusinessSupervisor_playbackList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['教务', '教务主管', '回放管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/refundAudit",
        name: "teachBusinessSupervisor_refundAudit",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_refundAudit" */ "../views/teachBusiness/teachBusinessSupervisor/refundAudit.vue"),
        meta: {
          title: '退款审核',
          breadList: ['教务', '教务主管', '退款审核'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/teachBusinessList",
        name: "teachBusinessSupervisor_teachBusinessList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_teachBusinessList" */ "../views/teachBusiness/teachBusinessSupervisor/teachBusinessList.vue"),
        meta: {
          title: '人员管理',
          breadList: ['教务', '教务主管', '人员管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/performanceList",
        name: "teachBusinessSupervisor_performanceList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_performanceList" */ "../views/teachBusiness/teachBusinessSupervisor/performanceList.vue"),
        meta: {
          title: '部门业绩',
          breadList: ['教务', '教务主管', '部门业绩'],
        },
      },
      /* 教务主管包含教务专员中的模块 */
      {
        path: "/teachBusiness/teachBusinessSupervisor/myIncome",
        name: "teachBusinessSupervisor_myIncome",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_myIncome" */ "../views/teachBusiness/teachBusinessAttache/myIncome.vue"),
        meta: {
          title: '员工收入',
          breadList: ['教务', '教务主管', '员工收入'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/classList",
        name: "teachBusinessSupervisor_classList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_classList" */ "../views/teachBusiness/teachBusinessAttache/classList.vue"),
        meta: {
          title: '班级管理',
          breadList: ['教务', '教务主管', '班级管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/courseRecord",
        name: "teachBusinessSupervisor_courseRecord",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_courseRecord" */ "../views/teachBusiness/teachBusinessAttache/courseRecord.vue"),
        meta: {
          title: '上课记录',
          breadList: ['教务', '教务主管', '上课记录'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/orderList",
        name: "teachBusinessSupervisor_orderList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_orderList" */ "../views/teachBusiness/teachBusinessAttache/orderList.vue"),
        meta: {
          title: '部门订单',
          breadList: ['教务', '教务主管', '部门订单'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/myTryCourse",
        name: "teachBusinessSupervisor_myTryCourse",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_myTryCourse" */ "../views/teachBusiness/teachBusinessAttache/myTryCourse.vue"),
        meta: {
          title: '部门试听',
          breadList: ['教务', '教务主管', '部门试听'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/arrangeTryCourse",
        name: "teachBusinessSupervisor_arrangeTryCourse",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_arrangeTryCourse" */ "../views/teachBusiness/teachBusinessAttache/arrangeTryCourse.vue"),
        meta: {
          title: '排试听课',
          breadList: ['教务', '教务主管', '排试听课'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/packageList",
        name: "teachBusinessSupervisor_packageList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_packageList" */ "../views/teachBusiness/teachBusinessAttache/packageList.vue"),
        meta: {
          title: '套餐添加',
          breadList: ['教务', '教务主管', '套餐添加'],
        },
      },
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      title: '404'
    }
  }
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(to => {
  NProgress.done()
  document.title = to.meta.title
})
export default router;
